// src/components/_CRUD/DataGrid.js

import React, {useEffect, useState} from 'react';
import {
    Badge,
    Box,
    Button,
    Spinner,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import {DeleteIcon, EditIcon} from '@chakra-ui/icons';
import {apiClient} from '../../apiClient';
import PermissionsCell from "../_UI/PermissionsCell";

const DataGrid = ({data, loading, config, onEdit, fetchData}) => {
    const toast = useToast();
    const [optionsData, setOptionsData] = useState({});

    useEffect(() => {
        // Fetch options data for select and array fields
        const fetchOptions = async () => {
            const newOptionsData = {};
            for (const field of config.fields) {
                if (['select', 'array', 'object'].includes(field.type) && field.optionsEndpoint) {
                    try {
                        const response = await apiClient.get(field.optionsEndpoint);
                        newOptionsData[field.name] = response.data[field.optionsDataPath];
                    } catch (error) {
                        console.error(`Error fetching options for ${field.name}:`, error);
                    }
                }
            }
            setOptionsData(newOptionsData);
        };

        fetchOptions();
    }, [config.fields]);

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            try {
                await apiClient.delete(config.apiEndpoints.delete.replace(':id', id));
                toast({
                    title: `${config.entity} deleted.`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
            } catch (error) {
                toast({
                    title: 'Error deleting item.',
                    description: error.message || 'Unable to delete the item.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    if (loading) {
        return (
            <Box textAlign="center" py={10}>
                <Spinner size="xl"/>
            </Box>
        );
    }

    // Filter fields based on showOnList
    const listFields = config.fields.filter((field) => field.showOnList !== false);

    return (

        <Box overflowX="auto">
            <Table variant={'simple'}>
                <Thead>
                    <Tr>
                        {listFields.map((field) => (
                            <Th key={field.name}>{field.label}</Th>
                        ))}
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((item) => (
                        <Tr key={item._id}>
                            {listFields.map((field) => (
                                <Td key={field.name}>
                                    {renderCellContent(item, field, optionsData)}
                                </Td>
                            ))}
                            <Td>
                                <Button
                                    size="xs"
                                    mr={2}
                                    leftIcon={<EditIcon/>}
                                    colorScheme="blue"
                                    onClick={() => onEdit(item)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    size="xs"
                                    colorScheme="red"
                                    leftIcon={<DeleteIcon/>}
                                    onClick={() => handleDelete(item._id)}
                                >
                                    Delete
                                </Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

const renderCellContent = (item, field, optionsData) => {
    const value = item[field.name];
    if (field.name === "permissions") {
        return <PermissionsCell permissions={item.permissions}/>;
    }

    if (field.type === 'select') {
        // Handle populated references
        if (typeof value === 'object' && value !== null) {
            return value[field.optionLabel] || value.name || 'N/A';
        } else {
            const options = optionsData[field.name] || [];
            const selectedOption = options.find(
                (option) => option[field.optionValue] === value
            );
            return selectedOption ? selectedOption[field.optionLabel] : value || 'N/A';
        }
    } else if (field.type === 'date') {
        if (field.type === 'date' && value) {
            // Format the date using Intl.DateTimeFormat
            return new Intl.DateTimeFormat('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            }).format(new Date(value)); // Return formatted date
        }
    } else if (field.type === 'array') {
        if (Array.isArray(value)) {
            return (
                <Wrap spacing="10px">
                    {value.map((val, index) => {
                        let displayValue = val;
                        if (typeof val === 'object' && val !== null) {
                            displayValue = val[field.optionLabel] || val.name || 'N/A';
                        } else {
                            const options = optionsData[field.name] || [];
                            const option = options.find(
                                (option) => option[field.optionValue] === val
                            );
                            displayValue = option ? option[field.optionLabel] : val;
                        }
                        return (
                            <WrapItem key={index}>
                                <Badge>{displayValue}</Badge>
                            </WrapItem>
                        );
                    })}
                </Wrap>
            );
        }
        return 'N/A';
    } else if (field.type === 'checkbox') {
        return value ? 'Yes' : 'No';
    } else if (typeof value === 'object' && value !== null) {
        // Check if the field is a populated reference (like department)
        if (value.name) {
            return value.name; // Display the 'name' field of the object
        }
        return JSON.stringify(value); // Fallback for other objects
    }
    return value || 'N/A';
};


export default DataGrid;

/*
      // OG:
      const options = optionsData[field.name] || [];
      if (Array.isArray(value)) {
          const labels = value.map((val) => {
              const option = options.find(
                  (option) => option[field.optionValue] === val
              );
              return option ? option[field.optionLabel] : val;
          });
          return labels.join(', ');
      }*/