// src/pages/admin/Settings.js
import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Select,
    Switch,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useColorMode,
    useToast,
    VStack,
} from '@chakra-ui/react';
import {useThemeContext} from '../../context/ThemeContext';
import {apiClient} from '../../apiClient';
import LocationCrud from './locations/LocationCrud'
import RoleCrud from "./roles/RoleCrud";
import PermissionCrud from "./permissions/PermissionCrud";
import ServiceItemCrud from "./service-items/ServiceItemCrud";
import TeamCrud from "./teams/TeamCrud";
import TimecardEntryOptionCrud from "./timecard-entry-options/TimecardEntryOptionCrud";
import UserCrud from "./users/UserCrud";
import DepartmentCrud from "./departments/DepartmentCrud";
import ColorPicker from '../../components/_UI/ColorPicker';


const Settings = () => {
    const {colorMode, toggleColorMode} = useColorMode();
    const toast = useToast();
    const {
        primaryColor,
        updatePrimaryColor,
        sidebarBg,
        updateSidebarBg,
    } = useThemeContext();

    // Local state for form inputs
    const [localPrimaryColor, setLocalPrimaryColor] = useState(primaryColor);
    const [localSidebarBg, setLocalSidebarBg] = useState(sidebarBg);

    useEffect(() => {
        // Fetch user settings from API
        const fetchSettings = async () => {
            try {
                const response = await apiClient.get('/users/me/settings');
                const settings = response.data || {};
                setLocalPrimaryColor(settings.primaryColor || primaryColor);
                setLocalSidebarBg(settings.sidebarBg || sidebarBg);
                if (settings.colorMode && settings.colorMode !== colorMode) {
                    toggleColorMode();
                }
            } catch (error) {
                console.error('Failed to fetch user settings:', error);
            }
        };

        fetchSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle form changes
    const handleColorModeToggle = () => {
        toggleColorMode();
    };

    const handlePrimaryColorChange = (e) => {
        setLocalPrimaryColor(e.target.value);
    };

    const handleSidebarBgChange = (color) => {
        setLocalSidebarBg(color);
    };

    // Apply changes
    const applyChanges = async () => {
        try {
            // Update theme context
            updatePrimaryColor(localPrimaryColor);
            updateSidebarBg(localSidebarBg);

            // Save settings to API
            await apiClient.put('/users/me/settings', {
                primaryColor: localPrimaryColor,
                sidebarBg: localSidebarBg,
                colorMode,
            });

            toast({
                title: 'Settings Updated.',
                description: 'Your theme settings have been successfully updated.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error updating settings.',
                description: error.message || 'Unable to update settings.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // Reset to default settings
    const handleReset = async () => {
        const defaultPrimaryColor = 'gray'; // Default color name
        const defaultSidebarBg = colorMode === 'dark' ? 'gray.700' : 'gray.400';

        setLocalPrimaryColor(defaultPrimaryColor);
        setLocalSidebarBg(defaultSidebarBg);

        // Reset theme context
        updatePrimaryColor(defaultPrimaryColor);
        updateSidebarBg(defaultSidebarBg);

        // Save default settings to API
        try {
            await apiClient.put('/users/me/settings', {
                primaryColor: defaultPrimaryColor,
                sidebarBg: defaultSidebarBg,
                colorMode: 'light',
            });

            if (colorMode === 'dark') toggleColorMode();

            toast({
                title: 'Settings Reset.',
                description: 'All theme settings have been reset to default.',
                status: 'info',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error resetting settings.',
                description: error.message || 'Unable to reset settings.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // Color options from theme.js
    const colorOptions = [
        {name: 'Brand (Default)', value: 'brand'},
        {name: 'Red', value: 'red'},
        {name: 'Blue', value: 'blue'},
        {name: 'Green', value: 'green'},
        {name: 'Yellow', value: 'yellow'},
        {name: 'Purple', value: 'purple'},
        {name: 'Teal', value: 'teal'},
        {name: 'Orange', value: 'orange'},
        {name: 'Pink', value: 'pink'},
        {name: 'Cyan', value: 'cyan'},
        {name: 'Gray', value: 'gray'},
    ];

    return (
        <Box>
            <Heading mb={6}>Settings</Heading>

            <Tabs variant={'enclosed'} colorScheme={'teal'}>
                <TabList>
                    <Tab>UI</Tab>
                    <Tab>Users</Tab>
                    <Tab>Locations</Tab>
                    <Tab>Departments</Tab>
                    <Tab>Roles</Tab>
                    <Tab>Permissions</Tab>
                    <Tab>Service Items</Tab>
                    <Tab>Teams</Tab>
                    <Tab>Timecard Entry Options</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <VStack spacing={6} align="start">
                            {/* Color Mode Toggle */}
                            <FormControl display="flex" alignItems="center">
                                <FormLabel htmlFor="color-mode" mb="0">
                                    Dark Mode
                                </FormLabel>
                                <Switch
                                    id="color-mode"
                                    isChecked={colorMode === 'dark'}
                                    onChange={handleColorModeToggle}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Primary Color</FormLabel>
                                <Select
                                    value={localPrimaryColor}
                                    onChange={handlePrimaryColorChange}
                                    maxW="200px"
                                >
                                    {colorOptions.map((color) => (
                                        <option key={color.value} value={color.value}>
                                            {color.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Sidebar Background Color Input */}
                            <FormControl>
                                <FormLabel>Sidebar Background Color</FormLabel>
                                <Box width="200px" >
                                    <ColorPicker
                                        width={300}
                                        height={150}
                                        value={localSidebarBg}
                                        onChange={handleSidebarBgChange}
                                    />
                                </Box>

                            </FormControl>

                            {/* Action Buttons */}
                            <Flex>
                                <Button colorScheme="teal" mr={4} onClick={applyChanges}>
                                    Apply Changes
                                </Button>
                                <Button colorScheme="red" onClick={handleReset}>
                                    Reset to Default
                                </Button>
                            </Flex>
                        </VStack>
                    </TabPanel>
                    <TabPanel>
                        <UserCrud/>
                    </TabPanel>
                    <TabPanel>
                        <LocationCrud/>
                    </TabPanel>
                    <TabPanel>
                        <DepartmentCrud/>
                    </TabPanel>

                    <TabPanel>
                        <RoleCrud/>
                    </TabPanel>
                    <TabPanel>
                        <PermissionCrud/>
                    </TabPanel>
                    <TabPanel>
                        <ServiceItemCrud/>
                    </TabPanel>
                    <TabPanel>
                        <TeamCrud/>
                    </TabPanel>
                    <TabPanel>
                        <TimecardEntryOptionCrud/>
                    </TabPanel>
                </TabPanels>

            </Tabs>
            {/*<VStack spacing={6} align="start">
                 Color Mode Toggle
                <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="color-mode" mb="0">
                        Dark Mode
                    </FormLabel>
                    <Switch
                        id="color-mode"
                        isChecked={colorMode === 'dark'}
                        onChange={handleColorModeToggle}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Primary Color</FormLabel>
                    <Select
                        value={localPrimaryColor}
                        onChange={handlePrimaryColorChange}
                        maxW="200px"
                    >
                        {colorOptions.map((color) => (
                            <option key={color.value} value={color.value}>
                                {color.name}
                            </option>
                        ))}
                    </Select>
                </FormControl>

                 Sidebar Background Color Input
                <FormControl>
                    <FormLabel>Sidebar Background Color</FormLabel>
                    <Input
                        type="text"
                        value={localSidebarBg}
                        onChange={handleSidebarBgChange}
                        placeholder="Enter color value (e.g., #2D3748)"
                        maxW="200px"
                    />
                </FormControl>

                 Action Buttons
                <Flex>
                    <Button colorScheme="teal" mr={4} onClick={applyChanges}>
                        Apply Changes
                    </Button>
                    <Button colorScheme="red" onClick={handleReset}>
                        Reset to Default
                    </Button>
                </Flex>
            </VStack>*/}
        </Box>
    );
};

export default Settings;
