
export const locationConfig = {
  entity: 'Location',
  apiEndpoints: {
    list: '/locations',
    create: '/locations',
    update: '/locations/:id',
    delete: '/locations/:id',
  },
  fields: [
  {
    "name": "name",
    "label": "Name",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  }
],
};
