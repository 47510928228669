// src/App.js
import React, {useMemo} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ChakraProvider} from '@chakra-ui/react';
import {AuthProvider} from './context/AuthContext';
import {ThemeProvider as CustomThemeProvider, useThemeContext} from './context/ThemeContext';
import {getTheme} from './theme';
import AdminLayout from './components/_Layout/AdminLayout';
import LoginPage from './pages/LoginPage';
import PublicPage from './pages/PublicPage';
import Dashboard from './pages/admin/Dashboard';
import Timecards from './pages/admin/timecards/Timecard';
import TimecardDashboard from './pages/admin/timecards/TimecardDashboard';
import TimecardEntries from './pages/admin/timecard-entries/TimecardEntry';
import Settings from './pages/admin/Settings';
// import {initializeCsrfToken} from './src/apiClient';
import CustomerPage from './pages/admin/customers/Customer';
import ProtectedRoute from './components/_Auth/ProtectedRoute';
import RoleProtectedRoute from './components/_Auth/RoleProtectedRoute';
import AlanPage from './pages/admin/alan/Alan';
import InventoryPage from './pages/admin/inventory/Inventory';
import ProjectPage from './pages/admin/projects/Project';
import HygienePage from './pages/admin/hygiene/Hygiene';

const ThemeWrapper = ({children}) => {
    const {primaryColor} = useThemeContext();
    const theme = useMemo(() =>
            getTheme({primaryColor}),
        [primaryColor]
    );
    return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}

const App = () => {
    return (
        <CustomThemeProvider>
            <ThemeWrapper>
                <Router>
                    <AuthProvider>
                        <Routes>
                            {/* Public Routes */}
                            <Route path="/" element={<PublicPage/>}/>
                            <Route path="/login" element={<LoginPage/>}/>
                            {/* TODO: Password reset route */}

                            {/* Protected Routes */}
                            <Route path="/admin/*"
                                   element={
                                       <ProtectedRoute>
                                           <AdminLayout/>
                                       </ProtectedRoute>
                                   }>
                                <Route path="dashboard" element={<Dashboard/>}/>
                                <Route path="alan" element={<AlanPage/>}/>
                                <Route path="projects" element={<ProjectPage/>}/>
                                <Route path="inventory" element={<InventoryPage/>}/>
                                <Route path="hygiene" element={<HygienePage/>}/>
                                <Route path="customers" element={<CustomerPage/>}/>
                                <Route path="timecards" element={<Timecards/>}/>
                                <Route path="timecard-dashboard" element={<TimecardDashboard/>}/>
                                <Route path="timecard-entries" element={<TimecardEntries/>}/>
                                <Route path="settings" element={<RoleProtectedRoute requiredRole='Admin'>
                                    <Settings/>
                                </RoleProtectedRoute>
                                }/>
                                {/* <Route path="locations" element={<Locations/>}/>
                                <Route path="permissions" element={<Permissions/>}/>
                                <Route path="roles" element={<Roles/>}/>
                                <Route path="teams" element={<Teams/>}/>
                                <Route path="users" element={<Users/>}/>
                                <Route path="service-items" element={<ServiceItems/>}/>
                                <Route path="timecard-entry-options" element={<TimecardEntryOptions/>}/>*/}
                            </Route>

                        </Routes>
                    </AuthProvider>
                </Router>
            </ThemeWrapper>
        </CustomThemeProvider>
    );
}


export default App;
