
export const timecardEntryConfig = {
  entity: 'TimecardEntry',
  apiEndpoints: {
    list: '/timecard-entries',
    create: '/timecard-entries',
    update: '/timecard-entries/:id',
    delete: '/timecard-entries/:id',
  },
  fields: [
  {
    "name": "description",
    "label": "Description",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "employeeId",
    "label": "Employeeid",
    "type": "select",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/users",
    "optionsDataPath": "users",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "approvedBy",
    "label": "Approvedby",
    "type": "select",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/users",
    "optionsDataPath": "users",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "createdBy",
    "label": "Createdby",
    "type": "select",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/users",
    "optionsDataPath": "users",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "projectId",
    "label": "Projectid",
    "type": "select",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/projects",
    "optionsDataPath": "projects",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "customerId",
    "label": "Customerid",
    "type": "select",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/customers",
    "optionsDataPath": "customers",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "serviceItemId",
    "label": "Serviceitemid",
    "type": "select",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/serviceitems",
    "optionsDataPath": "serviceitems",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "locationId",
    "label": "Locationid",
    "type": "select",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/locations",
    "optionsDataPath": "locations",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "dateCreated",
    "label": "Datecreated",
    "type": "date",
    "required": false,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "dateEdited",
    "label": "Dateedited",
    "type": "date",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "dateOfEntry",
    "label": "Dateofentry",
    "type": "date",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "startTime",
    "label": "Starttime",
    "type": "date",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "endTime",
    "label": "Endtime",
    "type": "date",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "totalTime",
    "label": "Totaltime",
    "type": "number",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "approvalDate",
    "label": "Approvaldate",
    "type": "date",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "isWorkOrder",
    "label": "Isworkorder",
    "type": "checkbox",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "isApproved",
    "label": "Isapproved",
    "type": "checkbox",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "isAfterHours",
    "label": "Isafterhours",
    "type": "checkbox",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "isBillable",
    "label": "Isbillable",
    "type": "checkbox",
    "required": false,
    "defaultValue": true,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "isPrevailingWage",
    "label": "Isprevailingwage",
    "type": "checkbox",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "isOvertime",
    "label": "Isovertime",
    "type": "checkbox",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  }
],
};
