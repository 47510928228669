// src/components/_Layout/AdminLayout.js
import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import BreadcrumbsComponent from './Breadcrumbs';
import { Outlet } from 'react-router-dom';

const AdminLayout = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    // Sidebar width based on `isSidebarOpen`
    const sidebarWidth = isSidebarOpen ? '250px' : '80px';

    return (
        <Flex direction="column" minH="100vh">
            <Header
                onToggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
                isSidebarOpen={isSidebarOpen}
            />
            <Flex flex="1" direction="row" overflow="hidden">
                <Sidebar isOpen={isSidebarOpen} />
                <Box
                    p={4}
                    flex="1"
                    minWidth="0"
                    overflow="auto"
                    ml={{ base: 0, md: sidebarWidth }}
                    mt="64px"
                    transition="margin-left 0.3s ease-in-out"
                >
                    <BreadcrumbsComponent />
                    <Box mt={4}>
                        <Outlet />
                    </Box>
                </Box>
            </Flex>
            <Footer />
        </Flex>
    );
};

export default AdminLayout;
