
import React from 'react';
import CustomerCrud from './CustomerCrud';

const CustomerPage = () => {
  return (
    <div>
      <h1>Customer Management</h1>
      <CustomerCrud />
    </div>
  );
};

export default CustomerPage;
