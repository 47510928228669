
export const teamConfig = {
  entity: 'Team',
  apiEndpoints: {
    list: '/teams',
    create: '/teams',
    update: '/teams/:id',
    delete: '/teams/:id',
  },
  fields: [
  {
    "name": "team",
    "label": "Team",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "internalId",
    "label": "Internalid",
    "type": "number",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "location",
    "label": "Location",
    "type": "select",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/locations",
    "optionsDataPath": "locations",
    "optionLabel": "name",
    "optionValue": "_id"
  },
  {
    "name": "teamLead",
    "label": "Teamlead",
    "type": "select",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/users",
    "optionsDataPath": "users",
    "optionLabel": "name",
    "optionValue": "_id"
  }
],
};
