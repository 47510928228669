
export const timecardEntryOptionConfig = {
  entity: 'TimecardEntryOption',
  apiEndpoints: {
    list: '/timecard-entry-options',
    create: '/timecard-entry-options',
    update: '/timecard-entry-options/:id',
    delete: '/timecard-entry-options/:id',
  },
  fields: [
  {
    "name": "name",
    "label": "Name",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "code",
    "label": "Code",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  }
],
};
