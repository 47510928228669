import { extendTheme } from '@chakra-ui/react';

export const customColors = {
    brand: {
        50: '#e3f9e5',
        100: '#c1eac8',
        200: '#a3d9a8',
        300: '#7bc97f',
        400: '#57b85a',
        500: '#3da34e',
        600: '#2f813b',
        700: '#1f5d29',
        800: '#0f3916',
        900: '#001304',
    },
    red: {
        50: '#ffe3e3',
        100: '#ffb3b3',
        200: '#ff8080',
        300: '#ff4d4d',
        400: '#ff1a1a',
        500: '#e60000',
        600: '#b30000',
        700: '#800000',
        800: '#4d0000',
        900: '#1a0000',
    },
    blue: {
        50: '#e3f2ff',
        100: '#b3d4ff',
        200: '#80b5ff',
        300: '#4d96ff',
        400: '#1a78ff',
        500: '#005ce6',
        600: '#004bb4',
        700: '#003982',
        800: '#002851',
        900: '#001820',
    },
    green: {
        50: '#e3f9e5',
        100: '#c1eac8',
        200: '#a3d9a8',
        300: '#7bc97f',
        400: '#57b85a',
        500: '#3da34e',
        600: '#2f813b',
        700: '#1f5d29',
        800: '#0f3916',
        900: '#001304',
    },
    yellow: {
        50: '#fffbea',
        100: '#fce588',
        200: '#fadb5f',
        300: '#f7c948',
        400: '#f0b429',
        500: '#de911d',
        600: '#c77b14',
        700: '#a3680a',
        800: '#854d07',
        900: '#633407',
    },
    purple: {
        50: '#f3e8ff',
        100: '#e9d5ff',
        200: '#d8b4fe',
        300: '#c084fc',
        400: '#a855f7',
        500: '#9333ea',
        600: '#7e22ce',
        700: '#6b21a8',
        800: '#581c87',
        900: '#4c1d95',
    },
    teal: {
        50: '#e6fffa',
        100: '#b2f5ea',
        200: '#81e6d9',
        300: '#4fd1c5',
        400: '#38b2ac',
        500: '#319795',
        600: '#2c7a7b',
        700: '#285e61',
        800: '#234e52',
        900: '#1d4044',
    },
    orange: {
        50: '#fff5e6',
        100: '#ffe0b3',
        200: '#ffc680',
        300: '#ffa64d',
        400: '#ff8321',
        500: '#e67300',
        600: '#b36200',
        700: '#804f00',
        800: '#4d3c00',
        900: '#1a2900',
    },
    pink: {
        50: '#ffe3f0',
        100: '#ffb3d4',
        200: '#ff80b3',
        300: '#ff4d99',
        400: '#ff1a80',
        500: '#e60066',
        600: '#b3004c',
        700: '#800033',
        800: '#4d001a',
        900: '#1a000f',
    },
    cyan: {
        50: '#e0ffff',
        100: '#b3ffff',
        200: '#80ffff',
        300: '#4dffff',
        400: '#1affff',
        500: '#00e6e6',
        600: '#00b3b3',
        700: '#008080',
        800: '#005c5c',
        900: '#003333',
    },
    gray: {
        50: '#f7fafc',
        100: '#edf2f7',
        200: '#e2e8f0',
        300: '#cbd5e0',
        400: '#a0aec0',
        500: '#718096',
        600: '#4a5568',
        700: '#2d3748',
        800: '#1a202c',
        900: '#171923',
    },
};

// Configuration for color mode
const config = {
    initialColorMode: 'light',
    useSystemColorMode: true,
};

// Function to create theme with dynamic primary color
const getTheme = ({ primaryColor }) => {
    return extendTheme({
        config,
        colors: {
            ...customColors,
            primary: customColors[primaryColor] || customColors.brand,
        },
        styles: {
            global: (props) => ({
                body: {
                    bg: props.colorMode === 'dark' ? 'gray.800' : 'gray.100',
                    color: props.colorMode === 'dark' ? 'white' : 'gray.800',
                },
            }),
        },
        components: {
            Link: {
                baseStyle: (props) => ({
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 'md',
                    p: 2,
                    _hover: {
                        textDecoration: 'none',
                        bg: props.colorMode === 'dark' ? 'gray.600' : 'gray.200',
                    },
                    _active: {
                        bg: props.colorMode === 'dark' ? 'gray.700' : 'gray.300',
                    },
                    color: props.colorMode === 'dark' ? 'white' : 'gray.800',
                }),
                variants: {
                    primary: (props) => ({
                        color: props.colorMode === 'dark' ? 'primary.200' : 'primary.100',
                        _hover: {
                            color: props.colorMode === 'dark' ? 'primary.700' : 'primary.600',
                        },
                    }),
                },
            },
            Button: {
                baseStyle: {
                    _focus: {
                        boxShadow: 'none',
                    },
                },
                variants: {
                    solid: (props) => ({
                        bg: `primary.500`,
                        color: 'white',
                        _hover: {
                            bg: `primary.600`,
                        },
                    }),
                },
            },
            Input: {
                variants: {
                    filled: (props) => ({
                        field: {
                            bg: props.colorMode === 'dark' ? 'gray.700' : 'gray.100',
                            _hover: {
                                bg: props.colorMode === 'dark' ? 'gray.600' : 'gray.200',
                            },
                            _focus: {
                                bg: props.colorMode === 'dark' ? 'gray.600' : 'gray.200',
                            },
                        },
                    }),
                },
            },
            Select: {
                variants: {
                    filled: (props) => ({
                        field: {
                            bg: props.colorMode === 'dark' ? 'gray.700' : 'gray.100',
                            _hover: {
                                bg: props.colorMode === 'dark' ? 'gray.600' : 'gray.200',
                            },
                            _focus: {
                                bg: props.colorMode === 'dark' ? 'gray.600' : 'gray.200',
                            },
                        },
                    }),
                },
            },
            Switch: {
                track: {
                    _checked: {
                        bg: 'primary.500',
                    },
                },
            },
            Checkbox: {
                control: {
                    _checked: {
                        bg: 'primary.500',
                        borderColor: 'primary.500',
                    },
                },
            },
            Tabs: {
                tab: {
                    _selected: {
                        color: 'primary.500',
                        borderColor: 'primary.500',
                    },
                },
            },
            // Add more component customizations as needed
        },
        breakpoints: {
            sm: '320px',
            md: '768px',
            lg: '960px',
            xl: '1200px',
            '2xl': '1536px',
        },
    });
};

export { getTheme };
