import React, { useState } from 'react';
import {
    Box,
    Flex,
    Heading,
    Input,
    Button,
    VStack,
    Container,
    HStack,
} from '@chakra-ui/react';
import TimecardForm from './TimecardForm';
import TimecardEntries from '../timecard-entries/TimecardEntry'; // A new component to list timecard entries
import WorkOrders from '../projects/ProjectCrud'; // A new component to list work orders

const TimecardDashboard = () => {
    const [employee, setEmployee] = useState("");
    const [filters, setFilters] = useState({ startDate: "", endDate: "" });

    const handleEmployeeChange = (e) => {
        setEmployee(e.target.value);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <Flex p={4} w="100vw" h="100vh">
            {/* Left Sidebar Form */}
            <Box w="30%" p={4} mr={4}>
                <Heading as="h4" size="md" mb={6}>Clock In to a Job</Heading>
                <TimecardForm employee={employee} />
            </Box>

            {/* Right Main Content */}
            <Box flex="1" p={4}>
                {/* Filter Bar */}
                <Box w="100%"  p={4} mb={4}>
                    <HStack spacing={4}>
                        <Input
                            placeholder="Enter Employee Name or ID"
                            value={employee}
                            onChange={handleEmployeeChange}
                        />
                        <Input
                            type="date"
                            name="startDate"
                            value={filters.startDate}
                            onChange={handleFilterChange}
                            placeholder="Start Date"
                        />
                        <Input
                            type="date"
                            name="endDate"
                            value={filters.endDate}
                            onChange={handleFilterChange}
                            placeholder="End Date"
                        />
                        <Button colorScheme="teal">Apply Filters</Button>
                    </HStack>
                </Box>

                {/* Timecard Entries Section */}
                <Box w="100%" p={4} mb={4}>
                    <Heading as="h4" size="md" mb={4}>Time Card Entries</Heading>
                    <TimecardEntries filters={filters} />
                </Box>

                {/* Related Work Orders Section */}
                <Box w="100%" p={4}>
                    <Heading as="h4" size="md" mb={4}>Work Orders</Heading>
                    <WorkOrders employee={employee} />
                </Box>
            </Box>
        </Flex>
    );
};

export default TimecardDashboard;
