// src/apiClient.js
import axios from 'axios';

export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL || '', // Set your API base URL here
    withCredentials: true, // Include cookies in requests
});

let csrfToken = '';

export const initializeCsrfToken = async () => {
    try {
        const response = await apiClient.get('/csrf/token');
        csrfToken = response.data['csrfToken'];
        apiClient.defaults.headers.common['X-CSRF-Token'] = csrfToken;
      //  console.log('CSRF token initialized:', csrfToken); // Add this line for debugging
    } catch (error) {
        console.error('Failed to fetch CSRF token:', error);
    }
};
// Re-initialize CSRF token on 403 responses
apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response && error.response.status === 403 && !error.config._retry) {
            error.config._retry = true;
            await initializeCsrfToken();
            error.config.headers['X-CSRF-Token'] = csrfToken;
            return apiClient.request(error.config);
        }
        return Promise.reject(error);
    }
);
