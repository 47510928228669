import React from "react";
import { Badge, Box } from "@chakra-ui/react";

const PermissionsCell = ({ permissions }) => {
    return (
        <Box>
            {permissions.map((permission, index) => (
                <Badge
                    key={`${permission.tool}-${permission.action}-${index}`}
                    colorScheme="teal"
                    mr={2}
                >
                    {`${permission.tool}: ${permission.action}`}
                </Badge>
            ))}
        </Box>
    );
};

export default PermissionsCell;
