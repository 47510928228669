
export const serviceItemConfig = {
  entity: 'ServiceItem',
  apiEndpoints: {
    list: '/service-items',
    create: '/service-items',
    update: '/service-items/:id',
    delete: '/service-items/:id',
  },
  fields: [
  {
    "name": "name",
    "label": "Name",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "description",
    "label": "Description",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  }
],
};
