// src/components/_Layout/Breadcrumbs.js
import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useLocation, Link as RouterLink } from 'react-router-dom';

const BreadcrumbsComponent = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <Breadcrumb spacing="8px" separator=">">
            <BreadcrumbItem>
                <BreadcrumbLink as={RouterLink} to="/">
                    Home
                </BreadcrumbLink>
            </BreadcrumbItem>
            {pathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                const isLast = index === pathnames.length - 1;
                return (
                    <BreadcrumbItem key={to} isCurrentPage={isLast}>
                        <BreadcrumbLink as={RouterLink} to={to}>
                            {value.charAt(0).toUpperCase() + value.slice(1)}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
};

export default BreadcrumbsComponent;
