import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Select,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { apiClient } from '../../../apiClient';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const TimecardForm = ({ employee, onSubmit, onClose, isOpen }) => {
    const [timecardEntries, setTimecardEntries] = useState([]);
    const toast = useToast();

    // Form validation schema
    const schema = yup.object().shape({
        startDate: yup.date().required('Start Date is required'),
        endDate: yup.date().nullable(),
        employeeId: yup.string().required('Employee ID is required'),
        workOrder: yup.string().required('Work Order selection is required'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            employeeId: employee,
            startDate: '',
            endDate: '',
        },
    });

    const fetchTimecardEntries = async (filterValues) => {
        try {
            const response = await apiClient.get(`/timecard-entries`, { params: filterValues });
            setTimecardEntries(response.data);
        } catch (error) {
            console.error('Error fetching Timecard Entries', error);
            toast({
                title: 'Error Fetching Entries',
                description: 'Could not retrieve Timecard entries.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const onSubmitFilter = (data) => {
        fetchTimecardEntries(data);
    };

    return (
        <>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerBody>
                        <Box p={4}>
                            <form onSubmit={handleSubmit(onSubmitFilter)}>
                                <Flex gap={4} mb={4}>
                                    <FormControl isInvalid={!!errors.startDate}>
                                        <FormLabel>Start Date</FormLabel>
                                        <Input type="date" {...register('startDate')} />
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.endDate}>
                                        <FormLabel>End Date</FormLabel>
                                        <Input type="date" {...register('endDate')} />
                                    </FormControl>
                                    <Button type="submit" colorScheme="teal">
                                        Apply Filters
                                    </Button>
                                </Flex>
                            </form>

                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Employee</Th>
                                        <Th>Day</Th>
                                        <Th>WO/KC</Th>
                                        <Th>Duration</Th>
                                        <Th>Hours</Th>
                                        <Th>Description of Work Performed</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {timecardEntries.map((entry) => (
                                        <Tr key={entry._id}>
                                            <Td>{entry.employeeName}</Td>
                                            <Td>{new Date(entry.dateOfEntry).toLocaleDateString()}</Td>
                                            <Td>{entry.workOrder || 'N/A'}</Td>
                                            <Td>{entry.duration}</Td>
                                            <Td>{entry.hours}</Td>
                                            <Td>{entry.descriptionOfWorkPerformed}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default TimecardForm;
