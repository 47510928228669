
import React from 'react';
import TimecardEntryCrud from './TimecardEntryCrud';

const TimecardEntryPage = () => {
  return (
    <div>
      <h1>Time Card Entry Management</h1>
      <TimecardEntryCrud />
    </div>
  );
};

export default TimecardEntryPage;
