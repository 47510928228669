// src/pages/Dashboard.js
import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

const Dashboard = () => {
    return (
        <Box p={6}>
            <Heading mb={6}>Admin Dashboard!</Heading>
        </Box>
    );
};

export default Dashboard;
