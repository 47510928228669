
import React, { useEffect, useState } from 'react';
import DataGrid from '../../../components/_CRUD/DataGrid';
import RightSidePanel from '../../../components/_CRUD/RightSidePanel';
import { departmentConfig } from '../../../config/department.config';
import { apiClient } from '../../../apiClient';
import { Box, Button, useDisclosure, useToast } from "@chakra-ui/react";

const DepartmentCrud = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(departmentConfig.apiEndpoints.list);
      setData(response.data);
    } catch (error) {
      toast({
        title: 'Error fetching data.',
        description: error.response?.data?.error || 'Unable to retrieve data.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreate = () => {
    setSelectedItem(null);
    onOpen();
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    onOpen();
  };

  const handleSubmit = async (formData) => {
    try {
      if (selectedItem) {
        const endpoint = departmentConfig.apiEndpoints.update.replace(':id', selectedItem._id);
        await apiClient.put(endpoint, formData);
        toast({
          title: 'Department updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        await apiClient.post(departmentConfig.apiEndpoints.create, formData);
        toast({
          title: 'Department created successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      fetchData();
      onClose();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  return (
    <Box>
      <Button
        colorScheme={'blue'}
        onClick={handleCreate}>
        Add Department
      </Button>
      <DataGrid
        data={data}
        loading={loading}
        config={departmentConfig}
        onEdit={handleEdit}
        fetchData={fetchData}
      />
      <RightSidePanel
        isOpen={isOpen}
        onClose={onClose}
        config={departmentConfig}
        item={selectedItem}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default DepartmentCrud;
