// src/context/AuthContext.js
import React, {createContext, useContext, useEffect, useState} from 'react';
import {apiClient, initializeCsrfToken} from '../apiClient';
import {useThemeContext} from "./ThemeContext";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state while checking auth
    const {fetchSettings} = useThemeContext();

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                // Initialize CSRF token
                await initializeCsrfToken();

                // Check if user is authenticated
                const response = await apiClient.get('/auth/check');
                setUser(response.data.user);

                if(response.data.user && response.data.user.roles){
                    const roles = response.data.user.roles.map((role)=>role.name);
                    setUserRoles(roles);
                }

                // Fetch settings
                if (response.data.user) {
                    await fetchSettings();
                }


            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Not authenticated, do nothing
                    setUser(null);
                } else {
                    console.error('Error initializing authentication:', error);
                }
            } finally {
                setLoading(false);
            }
        };

        initializeAuth();
    }, []);

    const login = async (credentials) => {
        try {
            // Ensure CSRF token is up-to-date
            await initializeCsrfToken();
            await apiClient.post('/auth/login', credentials);
            const response = await apiClient.get('/auth/check');
            setUser(response.data.user);

            if(response.data.user && response.data.user.roles){
                const roles = response.data.user.roles.map((role)=>role.name);
                setUserRoles(roles);
            }

            if (response.data.user) {
                await fetchSettings();
            }
        } catch (error) {
            throw error;
        }
    };

    const logout = async () => {
        try {
            // Ensure CSRF token is up-to-date
            await initializeCsrfToken();
            await apiClient.post('/auth/logout');
            setUser(null);
            setUserRoles([]);
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <AuthContext.Provider value={{user, userRoles, isAuthenticated: !!user, loading, login, logout}}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
