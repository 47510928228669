import React from "react";
import { Box, Checkbox, Grid, GridItem } from "@chakra-ui/react";

const PermissionsGrid = ({ toolsAndActions, selectedPermissions, onChange }) => {
    const handlePermissionToggle = (tool, action) => {
        const permission = `${tool}:${action}`;
        if (selectedPermissions.includes(permission)) {
            onChange(selectedPermissions.filter((perm) => perm !== permission));
        } else {
            onChange([...selectedPermissions, permission]);
        }
    };

    return (
        <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}>
            {Object.entries(toolsAndActions).map(([tool, actions]) => (
                <Box key={tool} borderWidth="1px" borderRadius="md" p={4}>
                    <strong>{tool}</strong>
                    <Grid mt={2}>
                        {actions.map((action) => {
                            const permission = `${tool}:${action}`;
                            return (
                                <GridItem key={`${tool}-${action}`}>
                                    <Checkbox
                                        isChecked={selectedPermissions.includes(permission)}
                                        onChange={() => handlePermissionToggle(tool, action)}
                                    >
                                        {action}
                                    </Checkbox>
                                </GridItem>
                            );
                        })}
                    </Grid>
                </Box>
            ))}
        </Grid>
    );
};

export default PermissionsGrid;
