// src/components/_Auth/RoleProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Center, Spinner } from '@chakra-ui/react';

const RoleProtectedRoute = ({ children, requiredRole }) => {
    const { userRoles, loading } = useAuth();

    if (loading) {
        return (
            <Center h="100vh">
                <Spinner size="xl" />
            </Center>
        );
    }

    if (!userRoles.includes(requiredRole)) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default RoleProtectedRoute;
