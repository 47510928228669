// src/pages/PublicPage.js
import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const PublicPage = () => {
    return (
        <Box textAlign="center" py={20} px={6}>
            <Heading as="h2" size="xl" mb={4}>
                Welcome to the Public Page
            </Heading>
            <Text color={'gray.500'}>
                This is a public page accessible without authentication.
            </Text>
            <Button
                colorScheme="teal"
                mt={6}
                as={RouterLink}
                to="/login"
            >
                Go to Login
            </Button>
        </Box>
    );
};

export default PublicPage;
