// src/components/_Layout/Footer.js
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useThemeContext } from '../../context/ThemeContext';

const Footer = () => {
    const { primaryColor } = useThemeContext();

    return (
        <Box
            bg={`${primaryColor}.500`}
            color="white"
            p={4}
            textAlign="center"
            mt="auto"
            width="100%"
            position="fixed"
            bottom="0"
            left="0"
            zIndex="docked"
        >
            <Text>&copy; {new Date().getFullYear()} Kaeb Sales. All rights reserved.</Text>
        </Box>
    );
};

export default Footer;
