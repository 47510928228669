
import React from 'react';
import TimecardCrud from './TimecardCrud';

const TimecardPage = () => {
  return (
    <div>
      <h1>Timecard Management</h1>
      <TimecardCrud />
    </div>
  );
};

export default TimecardPage;
