// src/context/ThemeContext.js

import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { apiClient } from '../apiClient';

const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [primaryColor, setPrimaryColor] = useState('gray'); // Default primary color
  const [sidebarBg, setSidebarBg] = useState('gray.400'); // Default Sidebar background

  // Fetch settings from API on mount
  const fetchSettings = useCallback(async () => {
    try {
      const response = await apiClient.get('/users/me/settings');
      const settings = response.data || {};
      if (settings.primaryColor) setPrimaryColor(settings.primaryColor);
      if (settings.sidebarBg) setSidebarBg(settings.sidebarBg);
    } catch (error) {
      console.error('Failed to fetch theme settings:', error);
    }
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  // Functions to update theme settings
  const updatePrimaryColor = (color) => {
    setPrimaryColor(color);
  };

  const updateSidebarBg = (color) => {
    setSidebarBg(color);
  };

  return (
    <ThemeContext.Provider
      value={{
        primaryColor,
        updatePrimaryColor,
        sidebarBg,
        updateSidebarBg,
        fetchSettings,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
