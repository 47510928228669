
export const projectConfig = {
  entity: 'Project',
  apiEndpoints: {
    list: '/projects',
    create: '/projects',
    update: '/projects/:id',
    delete: '/projects/:id',
  },
  fields: [
  {
    "name": "name",
    "label": "Name",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "description",
    "label": "Description",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  }
],
};
