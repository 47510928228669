import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    useToast,
} from "@chakra-ui/react";
import PermissionsGrid from "../../../components/_UI/PermissionsGrid";

const RoleForm = ({ initialRoleData, permissionsList, onSubmit }) => {
    const [roleName, setRoleName] = useState(initialRoleData?.name || "");
    const [roleDescription, setRoleDescription] = useState(initialRoleData?.description || "");
    const [selectedPermissions, setSelectedPermissions] = useState(
        initialRoleData?.permissions?.map((perm) => `${perm.tool}:${perm.action}`) || []
    );
    const [toolsAndActions, setToolsAndActions] = useState({});
    const toast = useToast();

    useEffect(() => {
        // Group permissions into tools and actions
        const groupedPermissions = permissionsList.reduce((acc, perm) => {
            if (!acc[perm.tool]) acc[perm.tool] = [];
            acc[perm.tool].push(perm.action);
            return acc;
        }, {});

        setToolsAndActions(groupedPermissions);
    }, [permissionsList]);

    const handleSave = async () => {
        try {
            const updatedPermissions = Array.from(
                new Set(
                    selectedPermissions.map((perm) => {
                        const [tool, action] = perm.split(":");
                        const matchingPermission = permissionsList.find(
                            (p) => p.tool === tool && p.action === action
                        );
                        return matchingPermission?._id;
                    })
                )
            );

            if (updatedPermissions.some((id) => !id)) {
                console.error("Invalid permissions found during mapping.");
                toast({ title: "Error mapping permissions.", status: "error" });
                return;
            }

            await onSubmit({
                name: roleName,
                description: roleDescription,
                permissions: updatedPermissions,
            });
            toast({ title: "Role saved successfully.", status: "success" });
        } catch (error) {
            console.error("Error saving role:", error);
            toast({ title: "Error saving role.", status: "error" });
        }
    };





    return (
        <Box>
            <FormControl mb={4}>
                <FormLabel>Name</FormLabel>
                <Input
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                    placeholder="Role Name"
                />
            </FormControl>
            <FormControl mb={4}>
                <FormLabel>Description</FormLabel>
                <Input
                    value={roleDescription}
                    onChange={(e) => setRoleDescription(e.target.value)}
                    placeholder="Role Description"
                />
            </FormControl>
            <FormControl mb={4}>
                <FormLabel>Permissions</FormLabel>
                <PermissionsGrid
                    toolsAndActions={toolsAndActions}
                    selectedPermissions={selectedPermissions}
                    onChange={setSelectedPermissions}
                />
            </FormControl>
            <Button onClick={handleSave} colorScheme="green">
                Save Role
            </Button>
        </Box>
    );
};

export default RoleForm;
