// src/components/_CRUD/RightSidePanel.js

import React from 'react';
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    useBreakpointValue,
} from '@chakra-ui/react';
import FormComponent from './Form';

const RightSidePanel = ({ isOpen, onClose, config, item, onSubmit }) => {
    const placement = useBreakpointValue({ base: 'bottom', md: 'right' });

    return (
        <Drawer isOpen={isOpen} placement={placement} onClose={onClose} size="md">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{item ? `Edit ${config.entity}` : `Add ${config.entity}`}</DrawerHeader>
                <DrawerBody>
                    <FormComponent config={config} initialData={item} onSubmit={onSubmit} />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default RightSidePanel;
