
export const permissionConfig = {
  entity: 'Permission',
  apiEndpoints: {
    list: '/permissions',
    create: '/permissions',
    update: '/permissions/:id',
    delete: '/permissions/:id',
  },
  fields: [
  {
    "name": "tool",
    "label": "Tool",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "action",
    "label": "Action",
    "type": "select",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "options": [
      {
        "label": "create",
        "value": "create"
      },
      {
        "label": "read",
        "value": "read"
      },
      {
        "label": "update",
        "value": "update"
      },
      {
        "label": "delete",
        "value": "delete"
      },
      {
        "label": "approve",
        "value": "approve"
      },
      {
        "label": "reject",
        "value": "reject"
      }
    ]
  },
  {
    "name": "description",
    "label": "Description",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  }
],
};
