// src/components/_UI/ColorPicker.js

import React from 'react';
import { ColorPicker as ReactColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/css';

const ColorPicker = ({ value, onChange }) => {
    const [color, setColor] = useColor('hex', value || '#ffffff');

    const handleChange = (newColor) => {
        setColor(newColor);
        onChange(newColor.hex);
    };

    return (
        <ReactColorPicker
            width={200}
            height={100}
            color={color}
            onChange={handleChange}
            hideHSV
            hideRGB
            dark
        />
    );
};

export default ColorPicker;
