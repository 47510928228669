// src/components/_Layout/Sidebar.js
import React, { useState } from 'react';
import {
    Box,
    Divider,
    Icon,
    Link,
    Spacer,
    Text,
    useBreakpointValue,
    VStack,
    Tooltip,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import {
    FaAddressBook,
    FaClock,
    FaCog,
    FaDashcube,
    FaWrench,
    FaUserTie,
    FaBaby,
    FaBoxes,
} from 'react-icons/fa';
import { PiBarnFill } from "react-icons/pi";
import {MdOilBarrel} from "react-icons/md";
import { useThemeContext } from '../../context/ThemeContext';

const Sidebar = ({ isOpen }) => {
    const { sidebarBg } = useThemeContext();
    const isDesktop = useBreakpointValue({ base: false, md: true });

    // Local state to handle hover expansion when sidebar is closed
    const [hovered, setHovered] = useState(false);

    const navItems = [
        { label: 'Dashboard', icon: FaDashcube, to: '/admin/dashboard' },
        { label: 'Customers', icon: PiBarnFill, to: '/admin/customers' },
        { label: 'Work Orders', icon: FaWrench, to: '/admin/projects' },
        { label: 'Inventory', icon: FaBoxes, to: '/admin/inventory' },
        { label: 'Hygiene', icon: MdOilBarrel, to: '/admin/hygiene' },
        { label: 'Time Card Dashboard', icon: FaClock, to: '/admin/timecard-dashboard' },
        { label: 'Time Cards', icon: FaClock, to: '/admin/timecards' },
        { label: 'Time Card Entries', icon: FaClock, to: '/admin/timecard-entries' },
        { label: 'Settings', icon: FaCog, to: '/admin/settings' },
        { label: 'Alan', icon: FaBaby, to: '/admin/alan' },
    ];

    const resourceItems = [
        { label: 'Directory', icon: FaAddressBook, to: '/admin/directory' },
        { label: 'Business Manager', icon: FaUserTie, to: '/admin/business-manager' },
        { label: 'HR Manager', icon: FaUserTie, to: '/admin/hr-manager' },
        { label: 'Payroll Manager', icon: FaUserTie, to: '/admin/payroll-manager' },
        { label: 'IT Manager', icon: FaUserTie, to: '/admin/it-manager' },
    ];

    // Determine effective open state:
    // - If parent says isOpen = true, always open
    // - If parent says isOpen = false and we're on desktop, use hover to open
    const effectiveOpen = isOpen || (isDesktop && hovered);
    const sidebarWidth = effectiveOpen ? '225px' : '55px';

    // Hide sidebar entirely on mobile if closed and not hovered (no hover on mobile anyway)
    if (!effectiveOpen && !isDesktop) {
        return null;
    }

    return (
        <Box
            as="nav"
            w={sidebarWidth}
            bg={sidebarBg}
            minH="calc(100vh - 64px)" // Adjust for header height
            p={2}
            pr={0}
            position="fixed"
            top="64px"
            left="0"
            transition="width 0.2s ease-in-out"
            overflow="hidden"
            zIndex="overlay"
            display="flex"
            flexDirection="column"
            // Only use hover handlers if isDesktop and isOpen is false
            onMouseEnter={() => {
                if (isDesktop && !isOpen) setHovered(true);
            }}
            onMouseLeave={() => {
                if (isDesktop && !isOpen) setHovered(false);
            }}
        >
            <VStack align="stretch" spacing={1} flex="1">
                {navItems.map((item) => {
                    const linkContent = (
                        <Link
                            as={NavLink}
                            to={item.to}
                            exact
                            activeclassname="active"
                            display="flex"
                            alignItems="center"
                            p={2}
                            pr={0}
                            borderRadius="md"
                            _hover={{ bg: 'gray.200' }}
                            _active={{ bg: 'gray.300' }}
                            overflow="hidden"
                        >
                            <Icon as={item.icon} boxSize={6} />
                            <Text
                                ml={4}
                                opacity={1}
                                width="auto"
                                overflow="hidden"
                                whiteSpace="nowrap"
                            >
                                {item.label}
                            </Text>
                        </Link>
                    );

                    return !effectiveOpen ? (
                        <Tooltip label={item.label} placement="right" key={item.label}>
                            {linkContent}
                        </Tooltip>
                    ) : (
                        <Box key={item.label}>{linkContent}</Box>
                    );
                })}
            </VStack>
            <Spacer />
            <Divider />
            <VStack align="stretch" spacing={1} mt={2} mb={2}>
                {resourceItems.map((item) => {
                    const linkContent = (
                        <Link
                            as={NavLink}
                            to={item.to}
                            exact
                            activeclassname="active"
                            display="flex"
                            alignItems="center"
                            p={2}
                            pr={0}
                            borderRadius="md"
                            _hover={{ bg: 'gray.200' }}
                            _active={{ bg: 'gray.300' }}
                            overflow="hidden"
                        >
                            <Icon as={item.icon} boxSize={6} />
                            <Text
                                ml={4}
                                opacity={1}
                                width={'auto'}
                                overflow="hidden"
                                whiteSpace="nowrap"
                            >
                                {item.label}
                            </Text>
                        </Link>
                    );

                    return !effectiveOpen ? (
                        <Tooltip label={item.label} placement="right" key={item.label}>
                            {linkContent}
                        </Tooltip>
                    ) : (
                        <Box key={item.label}>{linkContent}</Box>
                    );
                })}
            </VStack>
        </Box>
    );
};

export default Sidebar;
