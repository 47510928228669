// src/components/_Layout/Header.js
import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Flex,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from '../../context/ThemeContext';
import { useAuth } from '../../context/AuthContext';
import { FaBars, FaTimes } from 'react-icons/fa';
import logoUrl from '../../assets/logo.png';

const Header = ({ onToggleSidebar, isSidebarOpen }) => {
    const navigate = useNavigate();
    const { primaryColor } = useThemeContext();
    const { logout, user } = useAuth();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };
    const getInitials = (nameOrEmail) => {
        if (!nameOrEmail) return 'You';
        const names = nameOrEmail.split(' ');
        const initials = names.slice(0, 2).map((n) => n[0]).join('');
        return initials.toUpperCase();
    };

    const handleResetPassword = () => {
        navigate('/password-reset');
    };

    const companyName = 'Kaeb Sales';

    return (
        <Flex
            bg={`${primaryColor}.500`}
            color="white"
            p={4}
            align="center"
            wrap="wrap"
            width="100%"
            position="fixed"
            top={0}
            left={0}
            zIndex="docked"
            height="64px"
        >
            <IconButton
                aria-label="Toggle Sidebar"
                icon={isSidebarOpen ? <FaTimes /> : <FaBars />}
                onClick={onToggleSidebar}
                mr={4}
                colorScheme="whiteAlpha"
                variant="solid"
            />
            <Box>
                <Image src={logoUrl} alt={companyName} height="40px" />
            </Box>
            <Spacer />
            <Menu>
                <MenuButton
                    as={Button}
                    aria-label="Options"
                    colorScheme={primaryColor}
                    variant="outline"
                    _hover={{ bg: `${primaryColor}.600` }}
                    _expanded={{ bg: `${primaryColor}.600` }}
                    _focus={{ boxShadow: 'none' }}
                >
                    <Avatar size="sm" name={getInitials(user?.name) || 'User'} />
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={handleResetPassword}>Reset Password</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    );
};

export default Header;
