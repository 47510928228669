// src/components/_CRUD/Form.js

import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Stack,
    useToast,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select'; // Using react-select for better multi-select support
import {apiClient} from '../../apiClient';
const FormComponent = ({ config, initialData, onSubmit }) => {
    const [optionsData, setOptionsData] = useState({});
    const schema = buildValidationSchema(config.fields.filter((field) => field.showOnForm)); // Only validate fields that are shown on the form
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialData || {},
    });
    const toast = useToast();

    useEffect(() => {
        reset(initialData || {});
    }, [initialData, reset]);

    useEffect(() => {
        const fetchOptions = async () => {
            const newOptionsData = {};
            for (const field of config.fields.filter((field) => field.showOnForm)) { // Fetch options only for visible fields
                if (['select', 'array', 'object'].includes(field.type) && field.optionsEndpoint) {
                    try {
                        const response = await apiClient.get(field.optionsEndpoint);
                        newOptionsData[field.name] = response.data[field.optionsDataPath];
                    } catch (error) {
                        console.error(`Error fetching options for ${field.name}:`, error);
                    }
                }
            }
            setOptionsData(newOptionsData);
        };

        fetchOptions();
    }, [config.fields]);

    const handleFormSubmit = async (data) => {
        try {
            await onSubmit(data);
            toast({
                title: `${config.entity} successfully ${initialData ? 'updated' : 'created'}.`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'An error occurred.',
                description: error.message || 'Unable to process your request.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box p={4}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Stack spacing={4}>
                    {config.fields
                        .filter((field) => field.showOnForm) // Only render fields with showOnForm = true
                        .map((field) => (
                            <FormControl key={field.name} isInvalid={errors[field.name]}>
                                <FormLabel>{field.label}</FormLabel>
                                {renderField(field, register, optionsData, initialData, setValue)}
                                <FormErrorMessage>
                                    {errors[field.name] && errors[field.name].message}
                                </FormErrorMessage>
                            </FormControl>
                        ))}
                    <Button colorScheme="teal" isLoading={isSubmitting} type="submit">
                        Submit
                    </Button>
                </Stack>
            </form>
        </Box>
    );
};

const renderField = (field, register, optionsData, initialData, setValue) => {
  //  console.log('Initial Data:', initialData);
  //  console.log('Options Data:', optionsData?.[field.name]);

    switch (field.type) {
        case 'select': {
            let selectOptions = [];
            let selectedValue = null;

            if (field.name === 'teamLead') {
                // Special handling for TeamLead
                selectOptions = optionsData?.[field.name]?.map((option) => ({
                    value: option._id,
                    label: `${option.firstName} ${option.lastName}`, // Custom label combining first and last name
                })) || [];
                selectedValue = selectOptions.find(
                    (opt) =>
                        opt.value ===
                        (typeof initialData?.[field.name] === 'object'
                            ? initialData?.[field.name]?._id
                            : initialData?.[field.name])
                );
         //       console.log('Select Options (TeamLead):', selectOptions);
          //      console.log('Selected Value (TeamLead):', selectedValue);
            } else if (field.options) {
                // Local options (e.g., PayType)
                selectOptions = field.options.map((option) => ({
                    value: option.value,
                    label: option.label,
                }));
                selectedValue = selectOptions.find(
                    (opt) => opt.value === initialData?.[field.name]
                );
            } else if (optionsData?.[field.name]) {
                // Dynamic options fetched from API (e.g., Roles, Team, etc.)
                selectOptions = optionsData[field.name].map((option) => ({
                    value: option[field.optionValue || '_id'], // Default to `_id` if optionValue not defined
                    label: option[field.optionLabel || 'name'], // Default to `name` if optionLabel not defined
                }));
                selectedValue = selectOptions.find(
                    (opt) =>
                        opt.value ===
                        (typeof initialData?.[field.name] === 'object'
                            ? initialData?.[field.name]?._id
                            : initialData?.[field.name])
                );
            }

            return (
                <Select
                    options={selectOptions}
                    value={selectedValue || null} // Controlled value
                    onChange={(selectedOption) =>
                        setValue(field.name, selectedOption?.value || '') // Update state with selected value
                    }
                    isClearable // Allow clearing selection
                />
            );
        }

        case 'array': {
            // Handle multi-select fields like Roles
            const multiSelectOptions = optionsData?.[field.name]?.map((option) => ({
                value: option[field.optionValue || '_id'], // Default to `_id` if optionValue not defined
                label: option[field.optionLabel || 'name'], // Default to `name` if optionLabel not defined
            })) || [];
            const selectedValues = Array.isArray(initialData?.[field.name])
                ? initialData[field.name].map((value) =>
                    multiSelectOptions.find((opt) =>
                        typeof value === 'object' ? opt.value === value._id : opt.value === value
                    )
                )
                : [];
            return (
                <Select
                    isMulti
                    options={multiSelectOptions}
                    value={selectedValues} // Controlled value for multi-select
                    onChange={(selectedOptions) =>
                        setValue(
                            field.name,
                            selectedOptions ? selectedOptions.map((opt) => opt.value) : []
                        )
                    }
                />
            );
        }

        case 'checkbox':
            return (
                <Checkbox
                    isChecked={!!initialData?.[field.name]}
                    onChange={(e) => setValue(field.name, e.target.checked)}
                >
                    {field.label}
                </Checkbox>
            );

        case 'object': {
            const objectOptions = optionsData?.[field.name]?.map((option) => ({
                value: option._id,
                label: option.name,
            })) || [];
            const selectedObject = objectOptions.find(
                (opt) => opt.value === initialData?.[field.name]?._id
            );
            return (
                <Select
                    options={objectOptions}
                    value={selectedObject || null} // Controlled value
                    onChange={(selectedOption) => setValue(field.name, selectedOption?.value || '')}
                />
            );
        }

        default:
            return (
                <Input
                    type={field.type}
                    placeholder={`Enter ${field.label}`}
                    defaultValue={initialData?.[field.name] || ''}
                    {...register(field.name)}
                />
            );
    }
};





const buildValidationSchema = (fields) => {
    const shape = {};
    fields.forEach((field) => {
        let validator;
        switch (field.type) {
            case 'email':
                validator = yup.string().email('Invalid email format');
                break;
            case 'number':
                validator = yup.number().typeError(`${field.label} must be a number`);
                break;
            case 'checkbox':
                validator = yup.boolean();
                break;
            case 'array':
                validator = yup.array();
                break;
            default:
                validator = yup.string();
                break;
        }
        if (field.required) {
            validator = validator.required(`${field.label} is required`);
        }
        shape[field.name] = validator;
    });
    return yup.object().shape(shape);
};

export default FormComponent;
