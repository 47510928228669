
export const roleConfig = {
  entity: 'Role',
  apiEndpoints: {
    list: '/roles',
    create: '/roles',
    update: '/roles/:id',
    delete: '/roles/:id',
  },
  fields: [
  {
    "name": "name",
    "label": "Name",
    "type": "text",
    "required": true,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "description",
    "label": "Description",
    "type": "text",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true
  },
  {
    "name": "permissions",
    "label": "Permissions",
    "type": "array",
    "required": false,
    "defaultValue": null,
    "showOnList": true,
    "showOnForm": true,
    "optionsEndpoint": "/permissions",
    "optionsDataPath": "permissions",
    "optionLabel": "name",
    "optionValue": "_id"
  }
],
};
