import React, { useEffect, useState } from "react";
import { Box, Button, useDisclosure, useToast } from "@chakra-ui/react";
import RoleForm from "./RoleForm";
import DataGrid from "../../../components/_CRUD/DataGrid";
import { roleConfig } from "../../../config/role.config";
import { apiClient } from "../../../apiClient";

const RoleCrud = () => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // Fetch roles and permissions when the component loads
  useEffect(() => {
    const fetchRolesAndPermissions = async () => {
      try {
        const rolesResponse = await apiClient.get(roleConfig.apiEndpoints.list);
        setRoles(rolesResponse.data);

        const permissionsResponse = await apiClient.get("/permissions");
        setPermissions(permissionsResponse.data);
      } catch (error) {
        console.error("Error fetching roles or permissions:", error);
        toast({ title: "Error loading roles or permissions.", status: "error" });
      }
    };

    fetchRolesAndPermissions();
  }, []);

  const handleCreate = () => {
    setSelectedRole({
      name: "",
      description: "",
      permissions: [],
    });
    onOpen();
  };

  const handleEdit = (role) => {
    setSelectedRole(role);
    onOpen();
  };

  const handleDelete = async (roleId) => {
    try {
      await apiClient.delete(`${roleConfig.apiEndpoints.delete}/${roleId}`);
      toast({ title: "Role deleted successfully.", status: "success" });
      setRoles(roles.filter((role) => role._id !== roleId)); // Remove from the UI
    } catch (error) {
      console.error("Error deleting role:", error);
      toast({ title: "Error deleting role.", status: "error" });
    }
  };

  const handleSubmit = async (updatedRole) => {
    try {
      let response;
      if (selectedRole._id) {
        const endpoint = roleConfig.apiEndpoints.update.replace(':id', selectedRole._id);
        response = await apiClient.put(endpoint, updatedRole);

        // Replace the updated role in the list
        setRoles((prevRoles) =>
            prevRoles.map((role) =>
                role._id === response.data._id ? response.data : role
            )
        );
        toast({ title: "Role updated successfully.", status: "success" });
      } else {
        const endpoint = roleConfig.apiEndpoints.create;
        response = await apiClient.post(endpoint, updatedRole);

        // Add the new role to the list
        setRoles((prevRoles) => [...prevRoles, response.data]);
        toast({ title: "Role created successfully.", status: "success" });
      }
    } catch (error) {
      console.error("Error saving role:", error);

      // Display error toast
      toast({
        title: "Error saving role.",
        description: error?.response?.data?.message || "Server error occurred.",
        status: "error",
      });
    } finally {
      onClose();
    }
  };


  return (
      <Box>
        <Button colorScheme="blue" onClick={handleCreate}>
          Add Role
        </Button>
        <DataGrid
            data={roles}
            config={roleConfig}
            onEdit={handleEdit}
            onDelete={handleDelete}
        />
        {isOpen && (
            <RoleForm
                initialRoleData={selectedRole}
                permissionsList={permissions} // Pass permissions here
                onSubmit={handleSubmit}
            />
        )}
      </Box>
  );
};

export default RoleCrud;
